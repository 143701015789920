<template>
  <div>
    <LayoutHeader1 v-if="$route.path==='/home'" />
    <LayoutHeader2  v-else />
    <router-view />
    <LayoutFooter />
    <LayoutFooterFix />
  </div>
</template>


<script>
import LayoutHeader1 from "./components/Header";
import LayoutHeader2 from "./components/Header_s";
import LayoutFooter from "./components/Footer";
import LayoutFooterFix from "./components/FooterFix";
import {getCurrentInstance } from 'vue'

export default {
  name: "Layout",
  components: {
    LayoutHeader1,
    LayoutHeader2,
    LayoutFooter,
    LayoutFooterFix,
  },
  
  setup() {
  const { proxy } = getCurrentInstance()
    console.log(proxy.$root.$route)
    return {}
    }
  }


</script>

<style scoped>
</style>
