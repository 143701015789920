<template>
  <div class="footer-fix">
    <!-- <ul class="clearfix">
      <li>
        <router-link to="/home"><i class="i1"></i>网站首页</router-link>
      </li>
      <li>
        <router-link to="/news"><i class="i2"></i>新闻中心</router-link>
      </li>
      <li>
        <router-link to="/about/introduce"><i class="i3"></i>单位简介</router-link>
      </li>
      <li>
        <router-link to="/about/contact"><i class="i4"></i>联系我们</router-link>
      </li>
    </ul> -->
  </div>
</template>
<script>
export default {
  name: "FooterFix",
};
</script>

<style scoped>
</style>
