<template>
  <div class="g_index">
  <!--头部 s-->
    <div class="m_index_head clearfix">
      <div class="left_topnav">
      <div class="m_topNavs">
        <!-- <div class="m-login-box"> -->
          <!-- <router-link to="/user/login">登录个人中心</router-link> -->
          <!-- <a class="u_special btn-login" href="#" target="_blank">登录个人中心</a> -->
         <!-- <a class="btn-username z-none" href="#" title="" target="_blank"></a> -->
         <!-- <span>|</span> x
         <router-link tag="a" target="_blank" to="/downloadApp">移动版</router-link> -->
        <!-- </div>  -->
       </div>
    </div>
    <div class="company_name">
      <img class="top_logo" src="@/assets/image/logo_gdrm_red.png" >
      <p>重庆广大融媒科技有限公司</p>
    </div>
    
    <!--搜索 s-->
    <!-- <div class="m_search_area clearfix">
      <div class="m_search clearfix">
          <input type="text" 
          placeholder="搜索关键词" 
          ref="search" 
          id="keyword" 
          >
          <input id="searchbutton"  title="点击搜索" alt="搜索" value="搜索" @click="skip">
      </div>
    </div> -->
    </div>
    <!-- 导航栏 -->
    <div class="nav_box">
    <div class="focus_area clearfix">
        <ul class="index_nav clearfix">
        <li>
          <div class="index_item1">
            <router-link to="/home" tag="a" >首页</router-link>
          </div>
        </li>
        <li>
          <div class="index_item1">
            <router-link to="/news" tag="a">公司建设</router-link>
          </div>
          <div class="nav_item nav_item1">
            <ul>
              <li><router-link to="/news/company">公司新闻</router-link></li>
              <li><router-link to="/news/publish">通知公告</router-link></li>
              <li><router-link to="/party">党风建设</router-link></li>
            </ul>
          </div>
        </li>
        <li>
          <div class="index_item1">
            <router-link to="/ljsyy" tag="a">市级平台</router-link>
          </div>
          <div class="nav_item nav_item2">
            <ul>
              <li><router-link to="/ljsyy">简介</router-link></li>
              <li><router-link tag="a" target="_blank" to="/downloadApp">客户端下载</router-link></li>
              <li><router-link tag="a" target="_blank" to="/downloadUseManual">使用手册下载</router-link></li>
            </ul>
          </div>
        </li>
        <li>
          <div class="index_item1">
            <router-link to="/exhibition" tag="a">项目展示</router-link>
          </div>
        </li>
        <li>
          <div class="index_item1">
            <router-link to="/train" tag="a">培训教育</router-link>
          </div>
        </li>
        <!-- <li>
          <div class="index_item1">
            <router-link to="/activity" tag="a">员工风采</router-link>
          </div>
        </li> -->
        <li>
          <div class="index_item1">
            <router-link to="/rock" tag="a">他山之石</router-link>
          </div>
        </li>
        <li>
          <div class="index_item1">
            <router-link to="/tech" tag="a">技术前瞻</router-link>
          </div>
        </li>
        <!-- <li>
          <div class="index_item1">
            <router-link to="/party" tag="a">党风建设</router-link>
          </div>
        </li> -->
        <li>
          <div class="index_item1">
            <router-link to="/about" tag="a">关于我们</router-link>
          </div>
          <!-- <div class="nav_item nav_item3">
            <ul>
              <li><router-link to="/about/contact">联系我们</router-link></li>
              <li><router-link to="/about/introduce">单位简介</router-link></li>
            </ul>
          </div> -->
        </li>
        <li>
          <div class="index_item2">
            <!-- <router-link to="/user/login" tag="a">登录</router-link> -->
            <a href="https://oa.cqgdrm.com/user/login/">登录</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
  </div>
    
      <!-- <div class="nav">首页导航栏 -->
      <!-- <div class="container">中心盒子 -->
<!-- </div> -->
</template>

<script>
import axios from "@/api/apis";
import { onBeforeRouteUpdate } from "vue-router";
import { onMounted, nextTick, reactive, toRefs, getCurrentInstance } from "vue";
import { getFile } from "@/api/http";
import dateFormat from "@/utils/dateFormat";

export default {
  name: "Header",
   methods: {//事件方法
    skip(){//点击跳转搜索页面
      this.$router.push({
        path:"/searchresult",  //跳转到search.vue组件
        query: {
          search: this.$refs.search.value
        }
      })
    }, 
  },
};
</script>

<style scoped>
/* .m_index_head .left_topnav .m_topNavs{
  width: 560px;float: left;margin-top: -7px;height: 50px;} */
  .m_index_head .company_name{
    margin-top: 55px;

  }
.m_index_head .company_name P{
  display: inline-block;
  width: 30%;
  height: 50px;
  line-height: 50px;
  font-size: 30px;
  color: #e60012;
  margin-bottom: 1em;
  }

.m_index_head{width:1250px;margin:0 auto;}
/* .m_index_head .left_topnav{padding-top: 19px;line-height: 37px;} */
/* .m_index_head .left_topnav a{font-size: 16px;line-height: 24px;color:#fc3141;margin:0 15px;display:inline-block;}
.m_index_head .left_topnav a:hover{color:#bd1a2d;} */
.left_topnav .m-login-box {
  /* display: block; */
  /* float: right; */
  /* margin: 35px 30px 0 0; */
}

/* .m_index_head .left_topnav a.u_special,.m_index_head .left_topnav span{color:#bd1a2d;} */
.m-login-box{display:inline-block;font-size: 16px;}
.m_index_head .right_logo{float: right;padding-right: 240px;}
.m_index_head .m_logobox{padding:0 0 30px;text-align: center;clear:both;}
/* .m_index_head .company_name .top_logo{ 
  display: inline-block;
  width: 3%; 
  } */
.m_index_head .company_name img{ 
display: inline-block;
width: 3.5%; 
margin-top: -10px;
margin-right: 10px;

}
.btn-username{width:85px;text-overflow: ellipsis;white-space: nowrap;overflow:hidden;display:inline-block;position:relative;top:7px;}
.z-none{display: none!important;}
</style>
