<template>
  <div class="footer">
    <div class="container">
      <div class="g_links_box">
        <ul class="m_links_box f_clear">
          <li>
          <div class="m_links_module1">
            <select
              onchange="javascript:window.open(this.options[this.selectedIndex].value);this.selectedIndex=0"
            >
              <option selected="" value="">党群机构网站</option>

              <option value="http://www.cq.gov.cn/" target="_blank">
                重庆市人民政府网
              </option>
            </select>
          </div>
          </li>
          <li>
          <div class="m_links_module">
            <select
              onchange="javascript:window.open(this.options[this.selectedIndex].value);this.selectedIndex=0"
            > 
              <option selected="selected">区县融媒体中心</option>
              <option value="http://www.sxcm.net/" target="_blank">
                三峡传媒网
              </option>
              <option value="http://www.bycmw.com/" target="_blank">
                巴渝传媒网
              </option>
              <option value="http://www.cqjtv.com/" target="_blank">
                巴渝传媒网
              </option>
              <option value="http://www.fulingwx.com/" target="_blank">
                黔江广播电视台
              </option>
              <option value="http://www.cqyznews.com/" target="_blank">
                渝中新闻网
              </option>
              <option value="http://www.ddknews.gov.cn/" target="_blank">
                大渡口网
              </option>
              <option value="http://jbxww.cnnb.com.cn/" target="_blank">
                江北新闻网
              </option>
              <option value="https://shapingba.cbg.cn/" target="_blank">
                沙坪坝网络广播电视台网
              </option>
              <option value="http://www.cqjlp.com.cn/" target="_blank">
                全搜九龙坡网
              </option>
              <option value="http://www.cqna.com.cn/" target="_blank">
                南岸网
              </option>
              <option value="http://www.beibeinews.com/" target="_blank">
                北碚网
              </option>
              <option value="http://www.cqybq.gov.cn/" target="_blank">
                渝北网
              </option>
              <option value="http://www.bn.cq.cn/" target="_blank">
                巴南网
              </option>
              <option value="http://www.ccs.cn/index/" target="_blank">
                长寿新闻网
              </option>
              <option value="http://www.cqjjnet.com/" target="_blank">
                江津网
              </option>
              <option value="http://www.jrhcw.com/" target="_blank">
                今日合川网
              </option>
              <option value="http://www.ycw.gov.cn/" target="_blank">
                永川网
              </option>
              <option value="http://www.cqncnews.com/" target="_blank">
                南川网
              </option>
              <option value="https://www.cqqjnews.cn/" target="_blank">
                綦江网
              </option>
              <option value="http://www.txdzw.com/" target="_blank">
                大足网
              </option>
              <option value="http://www.bswxw.com/" target="_blank">
                璧山网
              </option>
              <option value="http://cqtl.cn:10/" target="_blank">铜梁网</option>
              <option value="http://www.cqtn.com/" target="_blank">
                潼南网
              </option>
              <option value="http://www.cqrc.org.cn/" target="_blank">
                荣昌新闻网
              </option>
              <option value="http://www.kxzc.cn/" target="_blank">
                开州之窗
              </option>
              <option value="http://www.lpnews.gov.cn/" target="_blank">
                梁平网
              </option>
              <option value="http://www.cqwulong.cn/" target="_blank">
                武隆网
              </option>
              <option value="http://www.ckxw.net/news/" target="_blank">
                城口新闻网
              </option>
              <option value="http://www.gcfd.cn/" target="_blank">
                丰都新闻网
              </option>
              <option value="http://www.cqdj.gov.cn/" target="_blank">
                垫江新闻网
              </option>
              <option value="http://www.zzxw.net/" target="_blank">
                忠州新闻网
              </option>
              <option value="http://www.xfjw.net/" target="_blank">
                奉节网
              </option>
              <option value="http://www.wushannews.com/" target="_blank">
                巫山网
              </option>
              <option value="http://www.zgsz.gov.cn/" target="_blank">
                石柱网
              </option>
              <option value="http://www.zgcqxs.net/" target="_blank">
                秀山网
              </option>
              <option value="http://www.yyxww.net/" target="_blank">
                酉阳新闻网
              </option>
              <option value="http://www.cqps.gov.cn/" target="_blank">
                彭水网
              </option>
              <option value="http://www.liangjiang.gov.cn/" target="_blank">
                两江新区网
              </option>
              <option value="http://cqwsnews.net/" target="_blank">
                万盛新闻网
              </option>
            </select>
          </div>
          </li>
          <li>
          <div class="m_links_module">
            <select
              onchange="javascript:window.open(this.options[this.selectedIndex].value);this.selectedIndex=0"
            >
              <option selected="selected">其他</option>
              <option value="https://zwykb.cq.gov.cn/" target="_blank">
                愉快办
              </option>
              <option value="https://www.cbg.cn/" target="_blank">
                视界网
              </option>
              <option value="http://www.cqnews.net/" target="_blank">
                华龙网
              </option>
              <option value="https://www.cqrb.cn/" target="_blank">
                重庆日报网
              </option>
              <option value="https://www.cqcb.com/hot/" target="_blank">
                上游新闻
              </option>
              
            </select>
          </div>
          </li>
        </ul>
      </div>
      <div class="foot_box">
        <div class="foot_center">
          <div class="footer-nav">
            <router-link to="/home">网站首页</router-link>
            <router-link to="/party/details">党风建设</router-link>
            <router-link to="/about/contact">联系我们</router-link>
            <router-link to="/about/introduce">单位简介</router-link>
          </div>
          <div class="footer-copy">
            <p>Copyright &copy; 2019-2021 重庆广大融媒科技有限公司</p>
            <p>
              <a href="https://beian.miit.gov.cn" target="_blank" rel="nofollow">备案号：渝ICP备2021004499号</a>
            </p>
          </div>
          <div class="footer-img">
            <div class="footer-img-box">
              <img src="../../../assets/image/微信公众号.jpg" alt="">
              <p>广大公司公众号</p>
            </div>
            <div class="footer-img-box">
              <img src="../../../assets/image/app二维码.png" alt="">
            <p>两江上游云app</p>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
</style>
